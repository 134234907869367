<template>
  <iq-card class="overflow-hidden border-cart-card">
    <b-card-header>
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-between align-center">
            <div class="cart-header-store d-flex align-items-center w-100">
              <div class="store-img">
                <img
                  :src="
                    cartItem.store.logo ||
                    require('@/assets/images/ibbil/ibbil-cover.jpg')
                  "
                  alt="img"
                  :style="{ 'background-color': '#fff' }"
                />
              </div>
              <div class="d-flex align-items-center">
                <h2 class="store-name">{{ cartItem.store.name }}</h2>
              </div>
            </div>
            <div class="cart-control">
              <i
                class="las la-angle-down"
                :class="{ 'la-angle-up': expanded }"
                @click="expanded = !expanded"
              ></i>
              <i
                class="las la-times"
                @click="deleteStoreFromCart(cartItem.id)"
              ></i>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-header>
    <div class="expanded-card" :class="{ open: expanded }">
      <b-row style="overflow: hidden">
        <b-col md="6">
          <b-card-body class="all-list-card">
            <div
              class="product-item-store d-flex"
              v-for="(product, key) in cartItem.cart_products"
              :key="key"
            >
              <div class="prod-img">
                <img :src="product.featured_image" alt="img" />
              </div>
              <div class="prod-details">
                <h4 class="product-name">{{ product.title }}</h4>

                <h6 class="product-price">
                  {{ totalPrice(product.price, product.quantity).toFixed(2) }}
                  {{ $t("marketplace.rs") }}
                </h6>
                <div class="controls d-flex align-items-center">
                  <button
                    @click="
                      increaseQuantity({
                        ...product,
                        store_id: cartItem.store.id,
                        cartId: cartItem.id,
                      })
                    "
                    class="btn--control"
                  >
                    <i class="las la-plus"></i>
                  </button>
                  <span class="font-size-20 m-0 px-3 text-primary">{{
                    product.quantity
                  }}</span>
                  <button
                    @click="
                      decreaseQuantity({
                        ...product,
                        store_id: cartItem.store.id,
                        cartId: cartItem.id,
                      })
                    "
                    class="btn--control"
                  >
                    <i class="las la-minus"></i>
                  </button>
                </div>
              </div>
              <button
                @click="
                  deleteProductInCart({
                    cartId: cartItem.id,
                    cartItemId: product.id,
                    productInCartId: product.cart_item_id,
                  })
                "
                class="delete-product"
              >
                <i class="las la-trash"></i>
              </button>
            </div>
          </b-card-body>
        </b-col>

        <b-col md="6">
          <div class="footer-cart-store">
            <b-row>
              <b-col cols="6">
                <div class="d-flex justify-content-start align-center">
                  <p class="total-price normal-text">
                    {{ $t("marketplace.totalPrice") }}
                  </p>
                </div>
              </b-col>
              <b-col cols="6">
                <div class="d-flex justify-content-end align-center">
                  <span class="total-price normal-text"
                    >{{ cartSubtotal }} {{ $t("main.sar") }}</span
                  >
                </div>
              </b-col>

              <b-col cols="6">
                <div class="d-flex justify-content-start align-center">
                  <p class="total-price normal-text">
                    {{ $t("marketplace.tax") }}
                  </p>
                </div>
              </b-col>
              <b-col cols="6">
                <div
                  class="d-flex justify-content-end align-center"
                  v-if="cartItem.cart_products"
                >
                  <span class="total-price normal-text"
                    >{{ taxes }} {{ $t("main.sar") }}</span
                  >
                </div>
              </b-col>
              <b-col class="border-top pt-2" cols="12">
                <b-row>
                  <b-col cols="6">
                    <div class="d-flex justify-content-start align-center">
                      <p class="total-price">{{ $t("marketplace.total") }}</p>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div
                      class="d-flex justify-content-end align-center"
                      v-if="cartItem.cart_products"
                    >
                      <span class="total-price"
                        >{{ cartTotal }} {{ $t("main.sar") }}</span
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <router-link
                  class="continue w-100 bg-primary d-block py-1 px-2 rounded-sm text-center"
                  :to="{
                    name: 'payment-actions',
                    params: { id: cartItem.id },
                  }"
                >
                  {{ $t("marketplace.continuePayment") }}
                </router-link>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <loader />
  </iq-card>
</template>

<script>
import { core } from "@/config/pluginInit";
import cartFunctions from "@/mixins/cartFunctions";
import productFunctions from "@/mixins/productFunctions";

import Loader from "@/components/core/loader/Loader.vue";

export default {
  name: "largeCart",
  props: ["cartItem", "idx"],
  mixins: [cartFunctions, productFunctions],
  components: {
    Loader,
  },
  data() {
    return {
      notEnoughProduct: false,
      expanded: this.idx == 0 ? true : false,
    };
  },
  computed: {
    cartSubtotal() {
      return +this.$store.getters["cart/getCartSubtotal"](
        this.cartItem.cart_products
      ).toFixed(2);
    },
    taxes() {
      const tax = +this.$store.getters["Setting/appSettings"]["tax_percentage"];
      return ((+this.cartSubtotal * tax) / 100).toFixed(2);
    },
    cartTotal() {
      return (+this.cartSubtotal + +this.taxes).toFixed(2);
    },
  },
  methods: {
    totalPrice(price, quantity) {
      return price * quantity;
    },
  },
  mounted() {
    core.index();
  },
};
</script>

<style lang="scss" scoped>
.font-size-22 {
  font-size: 22px !important;
}

.card-header {
  border-bottom: 0 !important;
}

.border-cart-card {
  border-radius: 4px !important;
  .expanded-card {
    display: grid;
    grid-template-rows: 0fr;
    transition: all 0.3s ease-in-out;
    &.open {
      grid-template-rows: 1fr;
    }
  }
}

.product-item-store {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #eeeeee;
  max-width: 400px;
  gap: 1rem;
}

.all-list-card .product-item-store:last-child {
  border-bottom: 0;
}

.store-img {
  width: 52px !important;
  height: 52px !important;
  border-radius: 50% !important;
  overflow: hidden;
}

.store-img img {
  object-fit: contain !important;
  object-position: center;
  width: 100%;
  height: 100%;
}

.prod-img {
  width: auto !important;
  height: 150px !important;
  border: none !important;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 100px !important;
  }
}

.prod-img img {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.prod-details {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .controls {
    margin: auto 0;
    width: 100%;
    .btn--control {
      background: transparent;
      border: 1px solid #bfbfbf;
      color: #000;
      border-radius: 4px;
      font-size: 0.75rem;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.delete-product {
  border: none;
  background: transparent;
  color: #d20000;
  padding: 0;
  align-self: flex-start;
  i {
    font-size: 1.5rem;
  }
}

.footer-cart-store {
  border: none !important;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 2.5rem;
  margin-top: 0.75rem;
}

.continue {
  &:hover {
    color: white !important;
  }
}

.w-88 {
  width: 88%;
  margin: auto;
}

@media (max-width: 768px) {
  .store-img h2 {
    font-size: 22px !important;
  }

  .min-quantity-items {
    font-size: 18px !important;
  }
  .footer-cart-store {
    padding: 1rem;
  }
}

.store-name {
  font-weight: 700 !important;
  font-size: 1.3rem !important;
  color: #000 !important;
  margin: 0 0.8rem !important;
}

.card-header {
  padding: 0 !important;
}

.iq-card {
  padding: 1rem !important;
}

.total-price {
  color: #000000 !important;
  font-size: 1.1rem;
  font-weight: 700;
}

.la-angle-down,
.la-times,
.la-angle-up {
  color: var(--iq-primary);
  font-size: 1.3rem;
  cursor: pointer;
  margin: 0 0.5rem;
}

.normal-text {
  font-size: 1rem;
  font-weight: 500;
}

.cart-control {
  display: flex;
  justify-content: end;
  align-items: center;

  .la-times {
    color: #d20000;
  }
}
</style>
