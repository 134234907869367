<template>
  <div class="mt-5 pb-5">
    <b-container>
      <div class="" v-if="cartItems.length > 0">
        <div v-for="(cartItem, idx) in cartItems" :key="idx">
          <large-cart :cartItem="cartItem" :idx="idx"></large-cart>
        </div>
      </div>
      <div v-else class="my-5">
        <empty-cart></empty-cart>
      </div>
    </b-container>
  </div>
</template>

<script>
import largeCart from "@/modules/servicePages/marketplace/components/cart/largeCart";

import emptyCart from "@/modules/servicePages/marketplace/components/cart/emptyCart";

export default {
  name: "cart",
  components: { largeCart, emptyCart },
  data() {
    return {
      loadingStore: false,
      products: [],
      selectedView: "largeCart",
    };
  },
  computed: {
    cartItems() {
      return this.$store.getters["cart/getProductCart"];
    },
  },
};
</script>
<style>
</style>
